import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import GroupIcon from "../../../../assets/svg/group-icon.svg";
import * as API from '../../../../common/api/index.js';
import URL from "../../../../common/api/constantURL";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { BiCommand } from "react-icons/bi";
import * as functions from "../../../../common/functions/validateFunctions";
import { setSnackData, setLoading } from '../../../../redux/action/userAction';
import CustomSelect from '../../../../common/component/SelectBox';
import {moveCardsData, setCardsData, selectDataMobile, selectAllData, selectData} from "../../../../common/functions/commonFunctions"
import Card from "../../../../common/component/Card";
import './CategoryFileManager.css';
import "../AddEvents.css";
import responseMessages from "../../../../common/variables/responseMessages.js";

function CategoryFileManager(props) {
    const groups = useSelector(state => state.groupReducer.groupInfo);
    const categoriesList = useSelector(state => state.docCategoryReducer.categoriesFiles)
    const fileUpload = useRef(null);
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [name, setName] = useState('');
    const [groupRestriction, setGroupRestriction] = useState(false);
    const [file, setFile] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isNameError, setIsNameError] = useState(false);
    const [isFileError, setIsFileError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [groupSelectionError, setGroupSelectionError] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [unAssignedGroups, setUnAssignedGroups] = useState([]);
    const [assignedSelectAllCheckBox, setAssignedSelectAllCheckBox] = useState(false);
    const [unassignedSelectAllCheckBox, setUnassignedSelectAllCheckBox] = useState(false);
    const [isActiveCategory, setIsActiveCategory] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectCategoryError, setSelectCategoryError] = useState(false);
    const [groupsData, setGroupsData] = useState([]);
    let selectBoxOptions = removeElementById(categoriesList.categories, props.data.id, props.isChild);
    const [depth, setDepth] = useState(0);

    const findParentCategory = (id, categories) => {  
        if (!id || !categories) return null;

        for (let obj of categories) {
            if (obj.id === id) return obj;
            if (obj.categories) {
                const category = findParentCategory(id, obj.categories);
                if (category) return category;
            }
        }
        return null;
    };

    useEffect(() => {
        if (props.data.id) {
            setGroupRestriction(props.data.isGroupRestriction);
            if (!props.isChild) {
                setName(props.data.name || '');
                setTitle(props.mode === "FILE" ? "Update Document/Image" : "Update Category");
                setSelectedCategory(findParentCategory(props.data.parentId, categoriesList.categories));
            } else {
                setTitle(props.mode === "FILE" ? "Add Document/Image" : "Add Category");
                setSelectedCategory(findParentCategory(props.data.id, categoriesList.categories))
            }
        } else {
            setTitle(props.mode === "FILE" ? "Add Document/Image" : "Add Category");
        }        
    },[props])

    useEffect(() => {
        setCardsData(groupsData, setUnAssignedGroups, setAssignedGroups,  groups);
        if (!groupRestriction) {
            setGroupSelectionError(false);
            setIsError(false)
        }
    }, [groupRestriction, groupsData])

    useEffect(() => {
        let isInherited = props.data.isInherited || false;
        if (selectedCategory?.groupIds) {
            setGroupsData(!isInherited && selectedCategory.id === props.data.parentId ? props.data.groupIds : selectedCategory.groupIds);
            setGroupRestriction(!isInherited && selectedCategory.id === props.data.parentId ? props.data.isGroupRestriction: selectedCategory.isGroupRestriction);
        } else {
            setGroupRestriction(props.data.isGroupRestriction || false);
            setGroupsData(props.data.groupIds || []);
        }
        setIsError(false);
    },[props, selectedCategory])

    const validateInputs = () => {
        if (!functions.validateInput(name, setErrorMessage, "all")) {
            setIsNameError(true);
            setIsError(true);
            return false;
        }
        if (name.trim().length > 150) {
            const errorType = props.mode === "FILE" ? "File name" : "Category name";
            setIsNameError(true);
            setErrorMessage(`${errorType} should not exceed 150 characters.`);
            setIsError(true);
            return false;
        }

        if (props.mode === "FILE" && (!props.data.mediaId && (!file || fileUpload.current.value === ''))) {
            setIsError(true);
            setIsFileError(true);
            setErrorMessage("Upload file");
            return false;
        }

        if (props.data.id && isFileError) {
            setErrorMessage("Upload file");
            return false;
        } 
    
        if (groupRestriction && assignedGroups.length === 0) {
            setGroupSelectionError(true);
            setErrorMessage("Select Groups to Continue");
            return false;
        }

        if (props.mode !== "FILE" && depth >= 5) {
            setIsError(true);
            setErrorMessage("Selected category already has 5 levels.");
            return false;
        }
    
        return true;
    };
    
    const handleAPIResponse = (response) => {
        if (response.fetchStatus === "success") {
            if (response.result.status === "success") {
                const snackData = {
                    showSnack: true,
                    snackMessage: response.result.message,
                    snackVariant: "success",
                };
                props.onCancel();
                dispatch(setSnackData(snackData));
            } else if (response.result.status === "client-error") {
                const snackData = {
                    showSnack: true,
                    snackMessage: response.result.message,
                    snackVariant: "error",
                };
                dispatch(setLoading(false));
                dispatch(setSnackData(snackData));
            } else {
                let snackData = {
                    showSnack: true,
                    snackMessage: response.result.message,
                    snackVariant: "error"
                }
                dispatch(setSnackData(snackData))
            }
        } else {
            const snackData = {
                showSnack: true,
                snackMessage: responseMessages.apiFailureResponse,
                snackVariant: "error",
            };
            dispatch(setLoading(false));
            dispatch(setSnackData(snackData));
        }
    };

    const processInputs = () => {
        const data = {
            "name": name,
            "facilityId": localStorage.getItem("facilityId"),
            "displayOrder": props.mode === "FILE" ? (categoriesList?.uncategorizedFiles[0]?.displayOrder ?? 0) + 1 : (categoriesList?.categories[0]?.displayOrder ?? 0) + 1,
            "isGroupRestriction": groupRestriction ? 1 : 0,
            "parentId" : selectedCategory ? selectedCategory.id : null,
        };
        
        if (selectedCategory?.id) {
            data["displayOrder"] = props.mode === "FILE" ? (selectedCategory?.files[0]?.displayOrder ?? 0) + 1 : (selectedCategory?.categories[0]?.displayOrder ?? 0) + 1;
            let assignedGroupIds = assignedGroups.length > 0 ? assignedGroups.map(obj => obj.id) : [];
            
            if (props.data.id && !props.isChild) {
                let addedGroupIds = assignedGroups.filter(group => !props.data.groupIds.includes(group.id)).map(obj => obj.id);
                let removedGroupIds = props.data.groupIds.filter(group => !assignedGroups.map(obj => obj.id).includes(group));
                
                if (addedGroupIds.length > 0 || removedGroupIds.length > 0) {
                    data["isInherited"] = JSON.stringify(assignedGroupIds.sort()) === JSON.stringify(selectedCategory.groupIds.sort()) ? 1 : 0;
                    data["addedGroupIds"] = addedGroupIds;
                    data["removedGroupIds"] = removedGroupIds;
                } else {
                    data["isInherited"] = 1;
                }

            } else {
                data["isInherited"] = JSON.stringify(assignedGroupIds.sort()) === JSON.stringify(selectedCategory.groupIds.sort()) ? 1 : 0;
                data["addedGroupIds"] = assignedGroupIds;
            }
        } else if (assignedGroups.length > 0) {

            if (props.data.id) {
                let addedGroupIds = assignedGroups.filter(group => !props.data.groupIds.includes(group.id)).map(obj => obj.id);
                let removedGroupIds = props.data.groupIds.filter(group => !assignedGroups.map(obj => obj.id).includes(group));

                if (addedGroupIds.length > 0 || removedGroupIds.length > 0) {
                    data["isInherited"] = 0;
                    data["addedGroupIds"] = addedGroupIds;
                    data["removedGroupIds"] = removedGroupIds;
                }else {
                    data["isInherited"] = 0;
                }

            } else {
                data["isInherited"] = 0;
                data["addedGroupIds"] = assignedGroups.map(obj => obj.id);
            }
        } else {
            data["isInherited"] = 0;
        }
        
        if (!groupRestriction) {
            data["removedGroupIds"] = assignedGroups.map(obj => obj.id);
        }

        if (props.data.id && !props.isChild) {
            data["id"] = props.data.id;
            data["displayOrder"] = props.data.displayOrder;
        } else {
            data["createdBy"] = localStorage.getItem("loginUserId");
        }

        if (props.mode === "FILE") {
            props.data.mediaId && (data["mediaId"] = props.data.mediaId);
            let form_data = new FormData();
            for (let key in data) {
                form_data.append(key, data[key]);
            }
            if (file) {
                form_data.append("dataFile", file);
            }
            return form_data;
        } else {
            return data;
        }

    }

    const saveCategory = async () => {

        if (!validateInputs()) return;

        const data = processInputs();

        dispatch(setLoading(true));
        let response = null
        if (props.data.id && !props.isChild) {
            if (props.mode === "FILE") {
                response = await API.putwithFileAPI(URL.files, data);
            } else {
                response = await API.putAPI(URL.categories, data);
            }
            dispatch(setLoading(false));
            handleAPIResponse(response);
        } else {
            if (props.mode === "FILE") {
                response = await API.postWithFileAPI(URL.files, data)
            } else {
                response = await API.postAPI(URL.categories, data)
            }
            dispatch(setLoading(false));
            handleAPIResponse(response);
        }

    }

    const handleFileChange = () => {
        const file = fileUpload.current.files[0];

        if (file) {
            const maxFileSize = 150 * 1024 * 1024;
            const allowedFormats = ['application/pdf', 'image/jpeg', 'image/png'];

            if (!allowedFormats.includes(file.type)) {
                fileUpload.current.value = '';
                setIsFileError(true);
                setIsError(true);
                setErrorMessage("Please upload a PDF, JPG, or PNG file.");
                return;
            }

            if (file.size > maxFileSize) {
                setIsError(true);
                setIsFileError(true);
                setErrorMessage('File size exceeds 150 MB.');
                return;
            }
            
            setFile(file);
            setIsError(false);
            setIsFileError(false);
        } else {
            setIsError(true);
            setIsFileError(true);
            setErrorMessage("Upload file");
            return;
        }
    };

    function removeElementById(data, idToRemove, forSubNode) {
        if ((!data && !idToRemove) || forSubNode) return data
        return data
            .filter(item => item.id !== idToRemove)
            .map(item => ({
                ...item,
                categories: item?.categories?.length > 0
                    ? removeElementById(item.categories, idToRemove, forSubNode)
                    : [],
            }));
    }
    return (
        <div className="add-group-container" data-testid="add-category-container-id">
            <div className="modal">
                <div className="modal-content-container add-category-modal">
                    <div className="modal-content add-category-modal-content ">
                        <div className="modal-title category-modal-title" data-testid="category-popup-title">
                           {title}
                        </div>
                        <div className='category-container'>
                            <div className={props.data.isImported && !props.isChild ? 'category-details-block restrict-event' : 'category-details-block'} data-testid="category-details-block" style={{ pointerEvents: props.data.isImported && !props.isChild ? "none" : "all" }}>
                            { (!props.isCategory && categoriesList.categories.length > 0) &&<div className="category-select-container">
                                <div className="group-filter-container-title" data-testid="category-dropdown-lable" id="category-select-title">Parent Category</div>
                                    <div className="group-filter-inner-container category-filter-container">
                                        <CustomSelect
                                            trigger={isActiveCategory}
                                            setTrigger={setIsActiveCategory}
                                            option={selectBoxOptions}
                                            selectItem={selectedCategory}
                                            selectedItem={setSelectedCategory}
                                            type="Category"
                                            valid={selectCategoryError}
                                            setValid={setSelectCategoryError}
                                            dataTestid="category-filer"
                                            depth = {setDepth}
                                        />
                                </div>
                            </div>}
                            <div className="group-name-container category-name-container" style={props.isCategory ? {marginBottom:"73px"} : {marginBottom:"0"}}>
                                <label
                                    htmlFor="categoryName"
                                    className={isNameError ? 'group-filter-container-title highLight' : 'group-filter-container-title'}
                                    data-testid="category-input-lable"
                                    id='label-name'
                                >
                                    {props.mode === "FILE" ? "Name *" : "Category Name *"}
                                </label>
                                <input
                                    id="categoryName"
                                    type="text"
                                    className={isNameError ? "event-input-field warn-msg" : "event-input-field"}
                                    data-testid="add-category-name-input-box"
                                    placeholder={props.mode === 'FILE' ? 'File Name' : 'Category Name'}
                                    value={name}
                                    disabled={props.data.isImported && !props.isChild}
                                    onChange={(event) =>{functions.validateInputChange(event,setName,setErrorMessage,"categoryName");setIsNameError(false);}}
                                />
                            </div>
                           
                                <div className='file-upload-ctr' style={props.mode === "FILE" ? {visibility: "visible"} : {visibility: "hidden"}} >
                                    <input
                                        className={isFileError ? 'warn-msg' : ''}
                                        type="file"
                                        data-testid="file-upload-input"
                                        accept=".pdf, .jpg, .png"
                                        onChange={handleFileChange}
                                        disabled={props.data.isImported && !props.isChild}
                                        ref={fileUpload}
                                    >  
                                    </input>
                                    <p data-testid="file-upload-info" className={isFileError ? 'highLight' : ''} style={{fontSize:"13px", marginTop:"5px"}}>{"(Supported Formats : PDF, JPG, PNG)"}</p>
                                </div>
                            
                            </div>
                            {/* 
                            Check-box to restrict groups for category
                            */}
                            <div className='category-groups-block'>
                            <div className='deployment-checkbox-ctr restrict-groups-checkbox'>
                                <span data-testid="group-restriction"><input data-testid="group-restriction-checkbox" type="checkbox"  className={selectedCategory?.isGroupRestriction ? "disablingEditConfig":null} disabled={selectedCategory?.isGroupRestriction} onClick={(e) => { setGroupRestriction(e.target.checked)}} checked={groupRestriction} /></span>
                                <span><img src={GroupIcon} alt="" /></span>
                                <span data-testid="restrict-groups-checkbox-lable" id='label-name'>Restrict Groups</span>
                                <span data-testid="restrict-groups-checkbox-txt" className='restrict-groups-checkbox-txt'>Restricting groups will only allow selected groups to view the file. If left unchecked, all users with access to Documents and Images will be able to view.</span>
                            </div>
                            {groupRestriction && <div>
                                <div className="group-management-container category-group-management-container">
                                    <div className="group-filter-container-title category-cards-title" id="group-managemnet-title" data-testid={"group-managemnet-title"}>Groups</div>
                                    <div className="group-management-card-container category-card-container" data-testid="group-management-card-container">
                                        <Card
                                            header="select"
                                            title="Groups Available"
                                            type="add-group"
                                            item={unAssignedGroups}
                                            selectedData={(data) => selectData(data, setSelectedGroups)}
                                            selectAll={(checkStatus, data) => { selectAllData(checkStatus, data, setSelectedGroups) }}
                                            selectedDataMobile={(data) => { selectDataMobile(data, selectedGroups, setSelectedGroups) }}
                                            setSelectAllStatus={(checkStatus) => { setUnassignedSelectAllCheckBox(checkStatus); setAssignedSelectAllCheckBox(false) }}
                                            checkedStatus={unassignedSelectAllCheckBox}
                                            selectionData={selectedGroups}
                                            dataTestId={"unassigned-group"}
                                            selectAllDataTestId={"unAssigned-checkbox-selectAll"}
                                            searchDataTestId={"unAssigned-search-filter-input-box"}
                                            cardContainerDataTestId={"group-card"}
                                        />
                                            <div className='user-list-option-container-list'>
                                                <BsArrowRight onClick={() => {
                                                    moveCardsData(
                                                        {
                                                          "selectedData": selectedGroups, 
                                                          "setSelectedData": setSelectedGroups, 
                                                          "assignedData": assignedGroups, 
                                                          "setAssignedData": setAssignedGroups, 
                                                          "unAssignedData": unAssignedGroups, 
                                                          "setUnAssignedData": setUnAssignedGroups,
                                                        },
                                                        groups, 
                                                        true
                                                      )
                                                    setGroupSelectionError(false);
                                                    setUnassignedSelectAllCheckBox(false)
                                                }
                                                } id="move-unAssigned-group" data-testid="move-unAssigned-group"
                                                />
                                                <BsArrowLeft onClick={() => {
                                                    moveCardsData(
                                                        {
                                                            "selectedData": selectedGroups, 
                                                            "setSelectedData": setSelectedGroups, 
                                                            "assignedData": assignedGroups, 
                                                            "setAssignedData": setAssignedGroups, 
                                                            "unAssignedData": unAssignedGroups, 
                                                            "setUnAssignedData": setUnAssignedGroups,
                                                        },
                                                        groups,
                                                        false
                                                    );
                                                    setAssignedSelectAllCheckBox(false)
                                                }}
                                                    id="move-Assigned-group" data-testid="move-Assigned-group"
                                                />
                                            </div>
                                        <Card

                                            type="add-group"
                                            title="In Group"
                                            item={assignedGroups}
                                            selectedData={(data) => selectData(data, setSelectedGroups)}
                                            selectedDataMobile={(data) => { selectDataMobile(data, selectedGroups, setSelectedGroups) }}
                                            selectAll={(checkStatus, data) => { selectAllData(checkStatus, data, setSelectedGroups) }}
                                            setSelectAllStatus={(checkStatus) => { setAssignedSelectAllCheckBox(checkStatus); setUnassignedSelectAllCheckBox(false) }}
                                            checkedStatus={assignedSelectAllCheckBox}
                                            selectionData={selectedGroups}
                                            dataTestId={"assigned-group-data"}
                                            selectAllDataTestId={"Assigned-checkbox-selectAll"}
                                            searchDataTestId={"unAssigned-search-filter-input-box"}
                                            cardContainerDataTestId={"group-card"}
                                        />
                                    </div>

                                </div>
                                <div className="row-6">
                                    <div className="card-instruction">
                                        <div>Hold down</div>
                                        <div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
                                        <div>, or </div>
                                        <div className="card-instruction-bold">&nbsp;<BiCommand />&nbsp;</div>
                                        <div>on a Mac to select more than one.</div>
                                    </div>
                                </div>
                            </div>
                            }
                            </div>
                        </div>
                        <div className='response-container' data-testid="category-popup-error-message-ctr" style={{ flex: '1 0 auto' }}>{isError || groupSelectionError ? errorMessage : " "}</div>
                        <div className="popup-button-container category-button-container">
                            <button data-testid="category-cancel-btn" className="cancel-button" 
                             style={{ flexShrink: '0' }}
                             onClick={(e) => {
                                    e.preventDefault();
                                    props.onCancel()
                                }}>Cancel</button>
                            <div className="vertical_line" ></div>
                            <button data-testid="category-save-btn" className="save-button"  onClick={()=>saveCategory()}  >
                                {props.data.id && !props.isChild ?
                                    "Update"
                                    :
                                    "Save"
                                }
                            </button>
                        </div >
                    </div>
                </div>
            </div>
        </div>
    )
}

CategoryFileManager.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
        isGroupRestriction: PropTypes.bool,
        parentId: PropTypes.number,
        groupIds: PropTypes.array,
        displayOrder: PropTypes.number,
        mediaId: PropTypes.string,
        isInherited: PropTypes.bool,
        isImported: PropTypes.bool
    }),
    isChild: PropTypes.bool,
    isCategory: PropTypes.bool,
    mode: PropTypes.string,
    onCancel: PropTypes.func
};

export default CategoryFileManager;
