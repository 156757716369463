import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import Table from '../../../common/component/Table';
import './Roles.css';
import Card from '../../../common/component/Card';
import {BsArrowRight} from 'react-icons/bs';
import {BsArrowLeft} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setSnackData, setLoading,setRolesDetails} from '../../../redux/action/userAction';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import Confirmation from '../../../common/component/Confirmation';
import {BiCommand} from "react-icons/bi"
import { setDeloyementConfig } from '../../../redux/action/deployementAction'; 
function Roles(){
	const rolesDetail = useSelector(state => state.userReducer.rolesDetails)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const params = useParams()
	const [confirmation,setConfirmation] =  useState(false)
	const [confirmationLoginRemove,setConfirmationLoginRemove] =  useState(false)
	const [deleteData,setDeleteData] = useState([])
	const [openRolesContainer,setOpenRolesContainer] = useState(false)
	const [autoFocusStatus,setAutoFocusStatus] = useState(true)
	const [roleData,setRoleData]=useState([])
	const [editRoleData,setEditRoleData] =  useState([])
	const [roleName,setRoleName]=useState("")
	const [isAdvisor,setIsAdvisor]=useState(0)
	
	const [roleNameError,setRoleNameError]=useState(false)
	const [errorStatus,setErrorStatus] = useState(false)
	const [ErrorMessage,setErrorMessage]=useState("")

	const [assignedWebPermissionDetails,setAssignedWebPermissionDetails] = useState([])
	const [unAssignedWebPermissionDetails,setUnAssignedWebPermissionDetails] = useState([])
	
	const [assignedMobilePermissionDetails,setAssignedMobilePermissionDetails] = useState([])  
	const [unAssignedMobilePermissionDetails,setUnAssignedMobilePermissionDetails] = useState([])
	
	const [selectedMobileData,setSelectedMobileData] = useState([])
	const [selectedWebData,setSelectedWebData] = useState([])
	
	const [mobileUnassignedSelectAll,setMobileUnAssignedSelectAll]=useState(false)
	const [mobileAssignedSelectAll,setMobileAssignedSelectAll]=useState(false)
	
	const [webUnassignedSelectAll,setwebUnAssignedSelectAll]=useState(false)
	const [webAssignedSelectAll,setwebAssignedSelectAll]=useState(false)
	const [parentMoveError,setParentMoveError] = useState("")
	const [loginPermissionIdExists, setLoginPermissionIdExists] = useState(false)
	const [loginPermissionId, setLoginPermissionId] = useState(null)
	const [confirmationAdvisorRemove,setConfirmationAdvisorRemove] =  useState(false)
	const dispatch = useDispatch()

	let tableHeaders = [
		{ id: 'roleName', label: 'Role Name', width: 200,dataTestid:"roleName", responsive: true, sortable: true },
	]
	useEffect(()=>{
		dispatch(setLoading(true))
		fetcRoleDetails();
	},[])

	
	const fetcRoleDetails = async () => {
		let response = await API.getAPI(URL.rolesByFacilityPermission + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setRolesDetails(userListTypes))
				fetchDeployementConfiguration()
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}	
	}
   
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/roles",display:"Roles",type:"react"},
	];
	const showEditAction=(data)=>{
		setErrorMessage("")
		setRoleNameError(false)
		setErrorStatus(false)
		 document.querySelector(".roles-page").scrollTo({
			top: 0,
			behavior: "smooth",
		})
		setAutoFocusStatus(true)
		setEditRoleData(data)
		setOpenRolesContainer(true)
		AssigningData(data)
	}
	function showDeleteAction(data){
		setOpenRolesContainer(false)
		setEditRoleData([])
		setConfirmation(true)
		setDeleteData(data)
	}
	const deleteRole = async()=>{
		dispatch(setLoading(true))
		let data = {
			"id": deleteData.id,
			"facilityId":localStorage.getItem("facilityId")
		}
		let response = await API.deleteAPI(URL.roles, data)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				setConfirmation(false)
				fetcRoleDetails()
				dispatch(setSnackData(snackData))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to delete. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const AssigningData=(data)=>{
		let permissionIds =[]
		if(data){
			setRoleName(data.roleName)
			setIsAdvisor(data.isAdvisor)
			permissionIds = data.permissionIds
		}else{
			setRoleName("")
			setIsAdvisor(0)
			permissionIds = []
		}
		
		let mobileAssignedPermission = []
		let mobileUnAssignedPermission = []
		let webAssignedPermission = [];
		let webUnAssignedPermission = [];
		if(Object.keys(rolesDetail).length > 0){
			rolesDetail.permissions.web.map((webPermissionData)=>{ 
				if(permissionIds && permissionIds.includes(webPermissionData.id))
				{
					webAssignedPermission.push({id:webPermissionData.id,item:webPermissionData.description,refName:webPermissionData.refName})
				}else{
					webUnAssignedPermission.push({id:webPermissionData.id,item:webPermissionData.description,refName:webPermissionData.refName})   
				}
			})
			rolesDetail.permissions.mobile.map((mobilePermissionData)=>{
				if(mobilePermissionData.refName === "CAN_LOGIN"){
					setLoginPermissionId(mobilePermissionData.id)
					if(permissionIds && permissionIds.includes(mobilePermissionData.id)){
						setLoginPermissionIdExists(true)
					}else{
						setLoginPermissionIdExists(false)
					}
				}
				if(permissionIds && permissionIds.includes(mobilePermissionData.id)){
					mobileAssignedPermission.push({id:mobilePermissionData.id,item:mobilePermissionData.description,refName:mobilePermissionData.refName})
				}else{
					mobileUnAssignedPermission.push({id:mobilePermissionData.id,item:mobilePermissionData.description,refName:mobilePermissionData.refName})
				}
			})
			setUnAssignedWebPermissionDetails(webUnAssignedPermission)
			setUnAssignedMobilePermissionDetails(mobileUnAssignedPermission)
			setAssignedMobilePermissionDetails(mobileAssignedPermission)
			setAssignedWebPermissionDetails(webAssignedPermission)
		}   
	}
	useEffect(() => {
		let mobileAssignedPermission = []
		let mobileUnAssignedPermission = []
		let webAssignedPermission = [];
		let webUnAssignedPermission = [];
		if(Object.keys(rolesDetail).length > 0){
			setRoleData(rolesDetail.roles)
			rolesDetail.permissions.web.map((webPermissionData)=>{  
				webUnAssignedPermission.push({id:webPermissionData.id,item:webPermissionData.description,refName:webPermissionData.refName})   
			})
			rolesDetail.permissions.mobile.map((mobilePermissionData)=>{
					mobileUnAssignedPermission.push({id:mobilePermissionData.id,item:mobilePermissionData.description,refName : mobilePermissionData.refName})
			})
			setUnAssignedWebPermissionDetails(webUnAssignedPermission)
			setUnAssignedMobilePermissionDetails(mobileUnAssignedPermission)
			setAssignedMobilePermissionDetails(mobileAssignedPermission)
			setAssignedWebPermissionDetails(webAssignedPermission)
		}  
	}, [rolesDetail]);

	useEffect(() => {
		if (rolesDetail.roles) {
			for (let index = 0; index < rolesDetail.roles.length; index++) {
				const userType = rolesDetail.roles[index];
				if (params.roleId && userType.id == params.roleId) {
					showEditAction(userType)
				}
			}
		}
	}, [rolesDetail]);
	
	const selectMobileData=(data)=>{
		setSelectedMobileData([...data])
	}
	const selectWebData=(data)=>{
		setSelectedWebData([...data])
	}

	const selectMobileDataMobile=(data)=>{
		setErrorStatus(false)
		let tempSelectedMobileData = selectedMobileData;
		let index = tempSelectedMobileData.indexOf(data)
		if (index > -1) {
			tempSelectedMobileData.splice(index, 1);
		}else{
			tempSelectedMobileData.push(data)
		}
		setSelectedMobileData([...tempSelectedMobileData])
	}
	const selectWebDataMobile=(data)=>{
		setErrorStatus(false)
		let tempSelectedWeb = selectedWebData;
		let index = tempSelectedWeb.indexOf(data)
		if (index > -1) {
			tempSelectedWeb.splice(index, 1);
		}else{
			tempSelectedWeb.push(data)
		}
		setSelectedWebData([...tempSelectedWeb])
	}
	const selectAllMobileData =(checkStatus,data)=>{
		if(checkStatus === true){
			let tempIds = (data.map((data)=>{
				return (data.id)
			}))
			setSelectedMobileData([...tempIds])
		}else{
			setSelectedMobileData([])
		}
	}
	const selectAllWebData =(checkStatus,data)=>{
	
		if(checkStatus === true){
			let tempIds = (data.map((data)=>{
				return (data.id)
			}))
			setSelectedWebData([...tempIds])
		}else{
			setSelectedWebData([])
		}
	}
	const moveSelectedMobilePermission = () => {
		setMobileUnAssignedSelectAll(false)
		setErrorStatus(false)
		let tempAssignedValues = assignedMobilePermissionDetails
		let tempUnAssignedValues = unAssignedMobilePermissionDetails
		let tempSelectedUsers = selectedMobileData;
		rolesDetail.permissions.mobile.map((user) => {  
			if (selectedMobileData.includes(user.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(user.id)) {  
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push({id:user.id,item:user.description,refName:user.refName})
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedMobileData ([...tempSelectedUsers])
		setAssignedMobilePermissionDetails([...tempAssignedValues])
		setUnAssignedMobilePermissionDetails([...tempUnAssignedValues])
	}
	const moveUnSelectedMobilePermission = () =>{
		setMobileAssignedSelectAll(false)
		let tempAssignedValues = assignedMobilePermissionDetails
		let tempUnAssignedValues = unAssignedMobilePermissionDetails
		let tempSelectedUsers = selectedMobileData;
		rolesDetail.permissions.mobile.map((user) => {
			if (selectedMobileData.includes(user.id) && tempAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push({id:user.id,item:user.description,refName:user.refName})
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedMobileData ([...tempSelectedUsers])
		setAssignedMobilePermissionDetails([...tempAssignedValues])
		setUnAssignedMobilePermissionDetails([...tempUnAssignedValues])   
	}

	function findRelation(options){
		let parent =[]
		let assignedRefName= []
		options.map((m)=>{ assignedRefName.push(m.refName)})

		let parentMap = {
			"ADD_USERS" : "VIEW_USERS", 
			"EDIT_USERS" : "VIEW_USERS" , 
			"DELETE_USERS" : "VIEW_USERS",

			"ADD_ROLES" : "VIEW_ROLES",
			"EDIT_ROLES" : "VIEW_ROLES", 
			"DELETE_ROLES" : "VIEW_ROLES",

			"ADD_GROUPS" : "VIEW_GROUPS", 
			"EDIT_GROUPS" : "VIEW_GROUPS" ,
			"DELETE_GROUPS" : "VIEW_GROUPS",

			"ADD_DEVICES" : "VIEW_DEVICES",
			"EDIT_DEVICES" : "VIEW_DEVICES", 
			"DELETE_DEVICES" : "VIEW_DEVICES", 

			"ADD_ALARMS" : "VIEW_ALARMS" , 
			"EDIT_ALARMS" : "VIEW_ALARMS" , 
			"DELETE_ALARMS" : "VIEW_ALARMS",

			"ADD_MUSTERING_LOCATION" : "VIEW_MUSTERING_LOCATION" , 
			"EDIT_MUSTERING_LOCATION" : "VIEW_MUSTERING_LOCATION" , 
			"DELETE_MUSTERING_LOCATION" : "VIEW_MUSTERING_LOCATION" , 

			"UPDATE_SERVER_CONFIG" : "VIEW_SERVER_CONFIG",

			"UPDATE_SERVICE_ALLOC" : "VIEW_SERVICE_ALLOC",

			"UPDATE_PROPERTIES" : "VIEW_PROPERTIES",

			"UPDATE_MUSTERING" : "VIEW_MUSTERING",

			"EDIT_ALERT_CONFIGURATION" : "VIEW_ALERT_CONFIGURATION",

			"EDIT_DEPLOYMENT_CONFIGURATION" : "VIEW_DEPLOYMENT_CONFIGURATION",

			"UPDATE_PROFILE" : "VIEW_PROFILE",

			"ADD_EVENTS" : "VIEW_EVENTS" , 
			"EDIT_EVENTS" : "VIEW_EVENTS" , 
			"DELETE_EVENTS" : "VIEW_EVENTS" ,

			"DELETE_DOCUMENTS_AND_IMAGES" : "VIEW_DOCUMENTS_AND_IMAGES",
			"EDIT_DOCUMENTS_AND_IMAGES" : "VIEW_DOCUMENTS_AND_IMAGES",
			"ADD_DOCUMENTS_AND_IMAGES" : "VIEW_DOCUMENTS_AND_IMAGES",
			
		}
			options.map((assigned)=>{
				let refname = assigned.refName 
				if(parentMap[refname]) {
					let parentRefname = parentMap[refname];
					if(!assignedRefName.includes(parentRefname) && !parent.includes(parentRefname)){parent.push(parentRefname)}
					}
			})
		return parent
	}
	const getRoleName= (roles,rolesRefName) =>{
        let arr =[]
        rolesRefName.forEach(function(role) {
            roles.forEach(function(roleRef){
                let grpp = roleRef.refName
                if(role === grpp){
                    arr.push({id:roleRef.id,item:roleRef.description,refName:roleRef.refName})
                }
            })
        });
        return arr
    }
	const moveSelectedWebPermissions = () =>{
		setParentMoveError("")
		setErrorStatus(false)
		setwebUnAssignedSelectAll(false)
		let tempAssignedValues = assignedWebPermissionDetails
		let tempUnAssignedValues = unAssignedWebPermissionDetails
		let tempSelectedUsers = selectedWebData;
	
		rolesDetail.permissions.web.map((user) => {
			if (selectedWebData.includes(user.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push({id:user.id,item:user.description,refName:user.refName})
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);  
			}
		})
		let parent = findRelation(tempAssignedValues)
		
		let refNameArr = getRoleName(rolesDetail.permissions.web,parent)
		refNameArr && refNameArr.forEach((p)=>{
			tempAssignedValues.push(p)
			tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === p.id),1)
			tempSelectedUsers.splice(tempSelectedUsers.indexOf(p.id), 1);  
		})
		setSelectedWebData ([...tempSelectedUsers])
		setAssignedWebPermissionDetails([...tempAssignedValues])
		setUnAssignedWebPermissionDetails([...tempUnAssignedValues])
	}

	const moveUnSelectedWebPermission =()=>{
		setParentMoveError("")
		setwebAssignedSelectAll(false)
		let tempAssignedValues = assignedWebPermissionDetails
		let tempUnAssignedValues = unAssignedWebPermissionDetails
		let tempSelectedUsers = selectedWebData;
	
		rolesDetail.permissions.web.map((user) => {
			if (selectedWebData.includes(user.id) && tempAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push({id:user.id,item:user.description})
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})

		let parent = findRelation(tempAssignedValues)
		let refNameArr = getRoleName(rolesDetail.permissions.web,parent)

		refNameArr.forEach((p)=>{
			if(refNameArr.includes(p)){
				setParentMoveError(`Cannot move "${p.item}" since its dependent permissions are in chosen web permission`)
			}
			tempAssignedValues.push(p)
			tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === p.id),1)
			tempSelectedUsers.splice(tempSelectedUsers.indexOf(p.id), 1);  
		})
	
		setSelectedWebData ([...tempSelectedUsers])
		setAssignedWebPermissionDetails([...tempAssignedValues])
		setUnAssignedWebPermissionDetails([...tempUnAssignedValues])
	}
	const addFormSubmitHandler =async(proceed,isAdvisorProceed)=>{
		setConfirmationLoginRemove(false)
		setConfirmationAdvisorRemove(false)

		let permissionIds = [];
		assignedMobilePermissionDetails.forEach(data => permissionIds.push(data.id));
		assignedWebPermissionDetails.forEach(data => permissionIds.push(data.id));

		if(!roleName.trim().length > 0){   
			setErrorMessage("Required Field")
			return setRoleNameError(true)
		}
		if(!permissionIds.includes(loginPermissionId) && loginPermissionIdExists && proceed){
			setConfirmationLoginRemove(true)
			return setErrorStatus(true)
		}
		// if(!permissionIds.length > 0){
		// 	setErrorMessage("Role should have atleast one permission.Select Permission.")
		// 	return setErrorStatus(true)
		// }
		let data = {
			"roleName":roleName.trim(),
			"isAdvisor":isAdvisor,
			"permissionIds":permissionIds,
			"facilityId":localStorage.getItem("facilityId"),
			"isCanLoginRemoved": !proceed,
		}
		let response;
		if(Object.keys(editRoleData).length > 0){
			data.id = editRoleData.id
			if (data.isAdvisor === 0 && isAdvisorProceed) {
				setConfirmationAdvisorRemove(true)
				return setErrorStatus(true)
			}
			response = await API.putAPI(URL.roles, data)
		}else{
			response = await API.postAPI(URL.roles, data)
		}
		
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){ 
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
				setRoleName("")
				setIsAdvisor(0)
				AssigningData()
				fetcRoleDetails();
				setOpenRolesContainer(false);
				setParentMoveError("");
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}             
		}else{
			let snackData = {
				showSnack:true,
				snackMessage:"Unable add. Try again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	if (validatePermission(userPermissions, "EDIT_ROLES") || validatePermission(userPermissions, "DELETE_ROLES")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 100, responsive: true })
	}

	const fetchDeployementConfiguration = async () => {
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=1";
        dispatch(setLoading(true))
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                let data = response.result.result
                dispatch(setDeloyementConfig(data))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
      
	}

	return (
		<div className="roles-page" id="wrapper">
			<BreadCrumb  crumbs={BreadCrumbArr} dataTestid="roles-page-breadcrum" ></BreadCrumb>
			<div className="roles-container">
				<div className="roles-listing-conatiner">
					<div className='list-page-title-div'>
						<div className="list-page-title" data-testid="roles-title">
							Role Management Options
						</div>
					</div>

					{validatePermission(userPermissions, "ADD_ROLES") ? 
						<div className="button_parent_div">
							<button className='common-button' onClick={() => { setOpenRolesContainer(true); AssigningData(); setEditRoleData([])}} id="roles-create-new-role-btn" data-testid="roles-create-new-role-btn">Create New Role</button>
						</div>
					:null}
					{validatePermission(userPermissions, "EDIT_ROLES") || validatePermission(userPermissions, "DELETE_ROLES") ?
						<div className="create-role-description" data-testid="manage-role-description">
							{validatePermission(userPermissions, "EDIT_ROLES") ?
								"Select the Edit Icon Below to Manage Roles."
							:null}
							{validatePermission(userPermissions, "DELETE_ROLES") ?
								" You Can Select Delete Icon to Remove Roles"
							: null}
						</div>
					: null}
					<div className="roles-list">
						<Table
							headers={tableHeaders}
							data={roleData}
							defaultOrderBy={"role"}
							defaultSortOrder={"asc"}
							editAction={validatePermission(userPermissions, "EDIT_ROLES") ? (data) => showEditAction(data) : null}
							deleteAction={validatePermission(userPermissions, "DELETE_ROLES") ? (data) => showDeleteAction(data) : null}
							isTitleVisible={false}
						/>
					</div>
				</div>
				{openRolesContainer && <div className="creating-roles-conatiner" data-testid="create-role-ctr">
						<div className="role-header-input">
							<div className='role-name-ctr'><div className='label-alighn-container'><label data-testid="role-name-label">Role Name</label><span className='required-symbol'>&nbsp;*</span></div><input type="text" data-testid="role-name-input" value={roleName} autoFocus={autoFocusStatus} placeholder="Choose Role Name" className={`"role-form-inputBox" ${roleNameError && "invalid-error"}`} onChange={(e)=>{setRoleName(e.target.value);setRoleNameError(false)}}/></div>
							<div className='role-checkbox-ctr' data-testid="role-advisor-checkbox-ctr"><input type="checkbox" data-testid="role-advisor-checkbox" checked={isAdvisor == 1 ? true:false} onChange={(e)=>{(e.target.checked)?setIsAdvisor(1):setIsAdvisor(0)}}/><p data-testid="advisor-description-ctr">Display associated users for this role in 'Advisor' <br/>drop down lists for the CATIE Mobile app.</p></div>
							<div><button className='role-save-button' data-testid="save-role-permission-button" onClick={()=>{addFormSubmitHandler(true,true)}}>Save Role & Permission</button></div>
						</div>
						<div className='role-error-msg' data-testid="role-error-message">{(roleNameError || errorStatus) && ErrorMessage}</div>
						<div className='role-form-description' data-testid="role-form-description-title">
							Manage CATIE Mobile App Permissions
						</div>
						<div className='role-form-description-details' data-testid="role-form-description-details">
							Enable CATIE Mobile App permissions by moving them from the left to the right column.
						</div>
						<div className="mobile-app-permission" data-testid="mobile-app-permission-conatiner">
							<div className='role-card-ctr'>
								<Card
									header="select"
									item={unAssignedMobilePermissionDetails}
									title='Mobile App Permissions'
									selectedData = {(data)=>selectMobileData(data)}
									selectedDataMobile = {(data)=>{selectMobileDataMobile(data)}}
									selectAll = {(checkStatus,data)=>{selectAllMobileData(checkStatus,data)}}
									setSelectAllStatus={(checkStatus)=>{setMobileUnAssignedSelectAll(checkStatus);setMobileAssignedSelectAll(false)}}
									checkedStatus={mobileUnassignedSelectAll}
									selectionData={selectedMobileData}
									dataTestId={"un-assigned-mobile-permission-card-data"}
									cardContainerDataTestId={"un-assigned-mobile-permission-card"}
									selectAllDataTestId={"un-assigned-mobile-permission-card-select-all"}
									searchDataTestId={"unAssigned-mobile-permission-search-filter-input-box"}
								
								/>
							</div>
							
							<div className="icon">
								<BsArrowRight onClick={moveSelectedMobilePermission} data-testid="move-unselected-mobile-permission"/>
								<BsArrowLeft onClick={moveUnSelectedMobilePermission} data-testid="move-selected-mobile-permission"/>
								
							</div >
							<div className='role-card-ctr'>
								<Card
									headers="deselect"
									item={assignedMobilePermissionDetails}
									selectedData = {(data)=>selectMobileData(data)}
									selectedDataMobile = {(data)=>{selectMobileDataMobile(data)}}
									title="Chosen Mobile App Permissions"
									selectionData={selectedMobileData}
									selectAll = {(checkStatus,data)=>{selectAllMobileData(checkStatus,data);}}
									setSelectAllStatus={(checkStatus)=>{setMobileAssignedSelectAll(checkStatus);setMobileUnAssignedSelectAll(false)}}
									checkedStatus={mobileAssignedSelectAll}
									dataTestId={"assigned-mobile-permission-card-data"}
									cardContainerDataTestId={"assigned-mobile-permission-card"}
									selectAllDataTestId={"assigned-mobile-permission-card-select-all"}
									
								/>
							</div>
							
						</div>
						<div className="roles-web-admin-permission-title" data-testid="roles-web-admin-permission-title">
							Manage CATIE Web Admin Permissions
						</div>
						<div className="roles-web-admin-permission-title-description" data-testid="roles-web-admin-permission-title-description">
							Enable CATIE Mobile Web admin permissions by moving them from the left to the right column.
						</div>
						<div className="web-app-permission" data-testid="web-app-permission-conatiner">
							<div className='role-card-ctr'>
								<Card
									header="select"
									item={unAssignedWebPermissionDetails}
									selectedData = {(data)=>selectWebData(data)}
									selectedDataMobile = {(data)=>{selectWebDataMobile(data)}}
									selectAll = {(checkStatus,data)=>{selectAllWebData(checkStatus,data)}}
									title='Web Admin Permissions'
									checkedStatus={webUnassignedSelectAll}
									setSelectAllStatus={(checkStatus)=>{setwebUnAssignedSelectAll(checkStatus);setwebAssignedSelectAll(false)}}
									selectionData={selectedWebData}
									dataTestId={"un-assigned-web-permission-card-data"}
									cardContainerDataTestId={"un-assigned-web-permission-card"}
									selectAllDataTestId={"un-assigned-web-permission-card-select-all"}
									searchDataTestId={"unAssigned-web-permission-search-filter-input-box"}
								/>
							</div>
							
							<div className="icon">
								<BsArrowRight onClick={moveSelectedWebPermissions} data-testid="move-unselected-web-permission"/>
								<BsArrowLeft onClick={moveUnSelectedWebPermission} data-testid="move-selected-web-permission"/>  
							</div >
							<div className='role-card-ctr'> 
								<Card
									headers="deselect"
									item={assignedWebPermissionDetails}
									selectedData = {(data)=>selectWebData(data)}
									selectedDataMobile = {(data)=>{selectWebDataMobile(data)}}
									title="Chosen Web Admin Permissions"
									selectAll = {(checkStatus,data)=>{selectAllWebData(checkStatus,data)}}
									checkedStatus={webAssignedSelectAll}
									setSelectAllStatus={(checkStatus)=>{setwebAssignedSelectAll(checkStatus);setwebUnAssignedSelectAll(false)}}
									selectionData={selectedWebData}
									dataTestId={"assigned-web-permission-card-data"}
									cardContainerDataTestId={"assigned-web-permission-card"}
									selectAllDataTestId={"assigned-web-permission-card-select-all"}
								/>
							</div>
							
						</div>
						<div className="card-instruction">
							<div>Hold down</div>
							<div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
							<div>, or </div>
							<div className="card-instruction-bold">&nbsp;<BiCommand/>&nbsp;</div>
							<div>on a Mac to select more than one.</div> 
						</div>
						<div className='role-error-msg' >{parentMoveError}</div>
				</div>}
			</div>
			{confirmationLoginRemove &&
					<Confirmation buttonValue="Proceed" deleteMessage={"!! All Users will be automatically logged out when ‘Can login’ permission is removed."} successFunc={() => { addFormSubmitHandler(false,true)}} cancelFunc={() => setConfirmationLoginRemove(false)} />
			}
			{confirmation &&
					<Confirmation  deleteMessage={"Are you sure you want to delete role "+deleteData.roleName+"?"} successFunc={() => deleteRole()} cancelFunc={() => setConfirmation(false)} />
			}
			{confirmationAdvisorRemove &&
					<Confirmation data-testid = "advisorRemoveConfirmationMsg" buttonValue="Proceed" deleteMessage={"Warning, removing the advisor privilege from this role will clear any previous advisor assignments for these users.  Are you sure you want to proceed?"} successFunc={() => { addFormSubmitHandler(false,false)}} cancelFunc={() => setConfirmationAdvisorRemove(false)} />
			}
		</div>
	)
}
export default Roles;
