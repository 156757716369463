import actiontypes from "../constant/action-types";
const initialState={
	categoriesFiles:[],
};
export const docCategoryReducer = (state = initialState, { type, payload})=>{
	switch (type){
		case actiontypes.SET_DOC_CATEGORY:
			return { ...state, categoriesFiles: payload};
		default :return state;
        }
}