import React from 'react'
import { useEffect } from 'react';
import "./Configuration.css"
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import ButtonField from '../../../common/component/ButtonField';
import { setLoading, setSnackData } from '../../../redux/action/userAction';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToneLibraryDropDown from '../../../common/component/ToneLibraryDropdown.js';
import uploadIcon from '../../../assets/svg/upload.svg'
import AudioFileUploadPopUp from './AudioFileUploadPopUp';
import { validatePermission } from "../../../common/functions/permissionFunctions";
//import { CompactPicker } from 'react-color';
import Table from '../../../common/component/Table';
import Confirmation from "../../../common/component/Confirmation"
import { setAlertTones } from '../../../redux/action/userAction';
import responseMessages from "../../../common/variables/responseMessages.js";

function Configuration() {
	const dispatch = useDispatch()
	const tones = useSelector(state => state.userReducer.alertTones)
	const [defaultTones, setDefaultTones] = useState([])
	const [customTones, setCustomTones] = useState([])
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const [alertDetails, setAlertDetails] = useState([])
	const [allAlertSetupUpDetails, setAllAlertSetupDetails] = useState('')
	const [activeGeneralAlert,setActiveGeneralAlert] = useState(false)
	const [activeMusteringAlert,setActiveMusteringAlert] = useState(false)
	const [activeQuickMsg,setActiveQuickMsg] = useState(false)
	const [triggeredColorPicker,setTriggeredColorPicker] = useState(false)
	const [editModalData,setEditModalData] = useState({})
	const [confirmation,setConfirmation] = useState(false)
	const [confirmationDeleteData,setConfirmationDeleteData] = useState([])

	const [selectedGeneralTone , setSelectedGeneralTone] = useState('')
	const [selectedMusteringTone , setSelectedMusteringTone] = useState('')
	const [selectedQuickMsgTone , setSelectedQuickMsgTone] = useState('')

	const [selectedServerDisNotifyTones , setSelectedServerDisNotifyTones] = useState('')

	const [isAudioUploadPopup , setIsAudioUploadPopup] = useState(false)

	const [selecteServerDisNotifyFile,setSelecteServerDisNotifyFile] = useState()

	const[enableServerNotifiy,setEnableServerNotify] = useState(false)
	const[enableAlertRemainderInterval,setEnableAlertRemainderInterval] = useState(false)
	const[enableClearRemainderInterval,setEnableClearRemainderInterval] = useState(false)
	const[enableCheckinRemianderInterval,setEnableCheckinRemianderInterval] = useState(false)
	const[enableEscalation,setEnableEscalation ] = useState(false)
	const[color,setColor] = useState({
		color: '#1569a8',
		active: false
	  })

	const[escalationDetails,setEscalationDetails] = useState([
		{
			escalationInterval : 15,
			escalationColor : "#000000"
		},
		{
			escalationInterval : 30,
			escalationColor : "#000000"
		}
	])


	useEffect(() => {
		fetchAlertSetupDetails()
	}, [])

	useEffect(() => {
		if (tones && Object.keys(tones).length > 0) {
			setDefaultTones(tones.defaultTones)
			setCustomTones(tones.customTones)
		}
	}, [tones])

	useEffect(() => {
	}, [selectedGeneralTone.url, selectedMusteringTone.url, selectedQuickMsgTone.url])
	
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/mustering",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/configurations/deployment-setup",display:"Configurations",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/configurations/alert-setup",display:"Alert Setup",type:"react"},
	];

	const fetchAlertSetupDetails = async () => {
		dispatch(setLoading(true))
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=2";
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		fetchTones()
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				setAlertDetails(response.result.result.Alert)
				setAllAlertSetupDetails(response.result.result)
				let tonesValues = response.result.result.Alert.toneDetails
				if(tonesValues){
					setSelectedGeneralTone(tonesValues.generalAlertTone ? tonesValues.generalAlertTone : "")
					setSelectedMusteringTone(tonesValues.criticalAlertTone ? tonesValues.criticalAlertTone : "")
					setSelectedQuickMsgTone(tonesValues.quickMessageTone ? tonesValues.quickMessageTone : "")
				}
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:responseMessages.apiFailureResponse,
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const UpdateAlertSetUpDetails = async()=>{
		dispatch(setLoading(true))
		let url = URL.updateAlertSetUp + "?facilityId=" + localStorage.getItem("facilityId");
		let data = {
			id: allAlertSetupUpDetails.id,
			facilityId: allAlertSetupUpDetails.facilityId,
			generalAlertTone : selectedGeneralTone.id,
			criticalAlertTone : selectedMusteringTone.id,
			quickMessageTone : selectedQuickMsgTone.id,
			...alertDetails
		}
		delete data.toneDetails

		let response = await API.putAPI(url, data)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage: responseMessages.apiFailureResponse,
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const deleteTone = async () => {
		let data = {
			"id": confirmationDeleteData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(URL.tones, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchTones();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: responseMessages.apiFailureResponse,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	function handleChange (e) {
		setColor({
		  oldColor: color.color,
		  color: e.target.value,
		  active: !color.active,
		});
	};

	function handleColorsClick(e){
		console.log(e)
	}

	function handleInterval(index,event){
		console.log(index)
		console.log(event)
	}
	function handleAddLevel(){
		setEscalationDetails([...escalationDetails,{
			escalationInterval : "",
			escalationColor : ""
		}])
	}

	let tableHeaders = [
		{ id: 'name', label: 'Tone Name', width: 200, responsive: true,dataTestId:"tone-name"},
        { id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 20, responsive: true }
	]

	const showEditAction = (data) =>{
		setEditModalData(data)
		setIsAudioUploadPopup(true)
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationDeleteData(data)
	}
	const fetchTones = async () => {
		dispatch(setLoading(true))
        let url = URL.tonesByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		dispatch(setLoading(false))
        let response = await API.getAPI(url)
        if (response.fetchStatus === "success") {
            dispatch(setAlertTones(response.result.result))
        } else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: responseMessages.apiFailureResponse,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
        }
    }

	const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    useEffect(() => {
        const handlePlay = (event) => {
            const audio = event.target;

            if (currentlyPlaying && currentlyPlaying !== audio) {
                currentlyPlaying.pause();
                currentlyPlaying.currentTime = 0;
            }
            setCurrentlyPlaying(audio);
        };

        const audios = document.querySelectorAll('audio');
        audios.forEach(audio => audio.addEventListener('play', handlePlay));
        return () => {
            audios.forEach(audio => audio.removeEventListener('play', handlePlay));
        };
    }, [currentlyPlaying]);

	return (
		<React.Fragment>
			<div className='alert-setup-container'>
				<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
				<div className='list-page-title-div'>
					<div className='list-page-title' data-testid="alert-set-up-title">Alert Setup</div>
				</div>
				<div className='alert-configuration-ctr'>
					<div className='alert-title' data-testid="alert-title">Alert Configurations</div>
					<div className='alert-display-order-ctr'>
						<div className='alert-title alert-sub-title'>Alert Display Order</div>
						<div className='alert-display-sort-ctr'>
							<div className='alert-display-radio-ctr'>
								<label
									data-testid={"role-radio-input-label"}
									id="role-radio-input"
									onClick={()=>{
										setAlertDetails({...alertDetails , alertDisplayOrder : 1})
									}}
								>
									Ascending
								</label>
								<input
									type="radio"
									name="Role"
									data-testid={'alert-asc-radio-input'}
									onChange={() => {}}
									id="role-input"
									checked={ alertDetails  && alertDetails.alertDisplayOrder === 1}
									onClick={()=>{
										setAlertDetails({...alertDetails , alertDisplayOrder : 1})
									}}
								/>
							</div>
							<div>
								<div  className='alert-display-radio-ctr'>
										<label
											onClick={()=>{
												setAlertDetails({...alertDetails , alertDisplayOrder : 2})
											}}
											data-testid={"role-radio-input-label"}
											id="role-radio-input"
										>
											Descending
										</label>
										<input
											checked={alertDetails  && alertDetails.alertDisplayOrder === 2}
											type="radio"
											name="Role"
											data-testid={'alert-desc-radio-input'}
											onClick={()=>{
												setAlertDetails({...alertDetails , alertDisplayOrder : 2})
											}}
											id="role-input"
										/>								
								</div>
								
								<div className='alert-order-hint'>Most recent on top</div>
							</div>
						</div>
					</div>
					<div className='toneContainer'>
						<div className='defaultAlertToneContainer'>
							<div className='defaultAlertToneTitleAndText'>
								<div className='alertToneTitle' data-testid = "default-alert-tones">Default Alert Tones</div>
								<div className='alertToneText' data-testid = "description">Audio tone set at the SARA device category level will override default tones</div>
							</div>
							<div className='audioCustomeContainer'>
								<div className='audioTableTitle'>
									<div className='audioTableCol1'></div>
									<div className='audioTableCol2'>Tone</div>
									<div className='audioTableCol3'>Preview</div>
									<div className='audioTableCol4'></div>
								</div>
								<div className='generalAlertContainer'>
									<div className='audioTableCol1'>
										<div className='alertType'>General Alert</div>
										<div className='tooltip-container'>
											<div className='tooltip-trigger question'>?</div>
											<div className='custom-tooltip'>Tone played when receiving normal (non-critical) alerts</div>
										</div>
									</div>
									<div className='audioTableCol2'>
										<div className='col2Container'>
											<div className='spanToneLibrary'>Tone</div>
											<ToneLibraryDropDown
												trigger={activeGeneralAlert}
												option = {tones ? [...defaultTones,...customTones] : []}
												selectedItem={setSelectedGeneralTone}
												selectItem={selectedGeneralTone.name}
												setTrigger={setActiveGeneralAlert}
												>
											</ToneLibraryDropDown>

										</div>
									</div>
									<div className='audioTableCol3'>
										<div className='spanPreview'>Preview</div>
										<audio controls src={selectedGeneralTone.url+"&index=1"} type="audio/ogg" className='preview-tone' data-testid = "general-tone-url"/>
									</div>
								</div>
								<div className='musteringAlertContainer'>
									<div className='audioTableCol1'>
											<div className='alertType'>Critical Alert</div>
											<div className='tooltip-container'>
												<div className='tooltip-trigger question'>?</div>
												<div className='custom-tooltip'>Tone played when receiving critical alerts</div>
											</div>
									</div>
									<div className='audioTableCol2'>
										<div className='col2Container'>
											<div className='spanToneLibrary'>Tone</div>
											<ToneLibraryDropDown
												trigger={activeMusteringAlert}
												option = {tones ? [...defaultTones,...customTones] : []}
												selectedItem={setSelectedMusteringTone}
												selectItem={selectedMusteringTone.name}
												setTrigger={setActiveMusteringAlert}
												>
											</ToneLibraryDropDown>
										</div>
									</div>
									<div className='audioTableCol3'>
										<div className='spanPreview'>Preview</div>
										<audio controls src={selectedMusteringTone.url+"&index=2"} type="audio/ogg" className='preview-tone' data-testid = "critical-tone-url"/>
									</div>
								</div>
								<div className='quickMessageContainer'>
									<div className='audioTableCol1'>
											<div className='alertType'>Quick Message</div>
											<div className="tooltip-container">
												<div className='question'>?</div>
												<div className='custom-tooltip'>Tone played when receiving a quick message from SARA</div>
											</div>
											
									</div>
									<div className='audioTableCol2'>
										<div className='col2Container'>
											<div className='spanToneLibrary'>Tone</div>
											<ToneLibraryDropDown
												trigger={activeQuickMsg}
												option = {tones ? [...defaultTones,...customTones] : []}
												selectedItem={setSelectedQuickMsgTone}
												selectItem={selectedQuickMsgTone.name}
												setTrigger={setActiveQuickMsg}
												>
											</ToneLibraryDropDown>
										</div>
									</div>
									<div className='audioTableCol3'>
										<div className='spanPreview'>Preview</div>
										<audio controls src={selectedQuickMsgTone.url+"&index=3"} type="audio/ogg" className='preview-tone' data-testid = "quick-tone-url"/>
									</div>
								</div>
							</div>


						</div>
						<div className='toneLibrayContainer'>
							<div className='alertToneTitle toneLibTitle' data-testid="tone-library">Custom Alert Tone Library</div>
							<div className='toneLibrary'>
								<div className='toneDescription'>Custom alert tones will only work with iOS devices. Android devices currently only support default alert tones.</div>
								<div className='audioTableCol4 uploadToneBtn' onClick={()=>{setIsAudioUploadPopup(true)}} data-testid="upload-tone-btn">
									<span className="upload-icon">
										<img src={uploadIcon} alt="uploadIcon"className='clearAlertUpload'/>
									</span>
									<div className='upload-text'>Upload New Tone</div>
								</div>
							</div>
							<Table
								headers={tableHeaders}
								data={customTones ? customTones: []}
								editAction={(data) => showEditAction(data)}
								deleteAction={(data) => showDeleteAction(data)}
								defaultOrderBy={"name"}
								defaultSortOrder={"asc"}	
								disablePagination={true}	
							/>
						</div>
					</div>
					
					{/* <div className='notifyOnServerDisconnectCont'>
						<span className='enable-severDisNotify-checkbox-ctr'>
							<span  className="enable-severDisNotify-label" onClick={()=>{setEnableServerNotify(!enableServerNotifiy)}}>Notify On Server Disconnect</span>
                            <input className='enable-severDisNotify-checkbox' type="checkbox"  checked={enableServerNotifiy} onClick={()=>{setEnableServerNotify(!enableServerNotifiy)}}/>
                         </span>
						 <div className='enableServerDisNotifyDescription'>When Enabled tones can be customized</div>
						<div className={enableServerNotifiy ? "serverUploadToneCtr" : 'serverUploadToneCtr interval-show-cont'}>
							<div className='audioTableTitle'>
								<div className='audioTableCol2'>Tone Library</div>
								<div className='audioTableCol3'>Preview</div>
								<div className='audioTableCol4'></div>
							</div>
							<div className='alarmClearedContainer serverNotify'>
								<div className='audioTableCol2'>
									<div className='col2Container'>
										<div className='spanToneLibrary'>Tone Library</div>
										<ToneLibraryDropDown
												trigger={activeServerDisNotify}
												option = {serverDisNotifyTones}
												selectedItem={setSelectedServerDisNotifyTones}
												selectItem={selectedServerDisNotifyTones}
												setTrigger={setActiveServerDisNotify}
												selectedFile={setSelecteServerDisNotifyFile}
												>
										</ToneLibraryDropDown>
									</div>
								</div>
								<div className='audioTableCol3'>
										<div className='spanPreview'>Preview</div>
										<audio controls src={selecteServerDisNotifyFile} type="audio/ogg" className='preview-tone' disabled={!enableServerNotifiy}/>
								</div>
								<div className='audioTableCol4' onClick={()=>{handleAudioUploadPopup('Server Disconnect Notify')}}>
									<span className="upload-icon">
										<img src={uploadIcon} className='clearAlertUpload'/>
									</span>
									<div className='upload-text'>Upload New Tone</div>
								</div>
							</div>
						</div>
					</div> */}

					{/* <div className='remainder-interval-cont'>
						<div className='alertRemainderIntervalCont'>
							<span className='enable-severDisNotify-checkbox-ctr  justify-content-align-item'>
								<span  className="enable-severDisNotify-label" onClick={()=>{setEnableAlertRemainderInterval(!enableAlertRemainderInterval)}}>Alert Reminder Interval</span>
								<input className='enable-severDisNotify-checkbox' type="checkbox"  checked={enableAlertRemainderInterval} onClick={()=>{setEnableAlertRemainderInterval(!enableAlertRemainderInterval)}}/>
							</span>
							<div className='enableServerDisNotifyDescription'>If enabled, Alert reminders will be sent at the specified interval below.</div>
							<div className={enableAlertRemainderInterval ? "" : 'interval-show-cont'}>
								<div className='remainder-interval'>Reminder Interval</div>
								<div className='interval-cont'>
									<div className='every'>Every</div>
									<input className='interval-input' disabled={!enableAlertRemainderInterval}></input>
									<div className='minutes'>minutes</div>
								</div>
							</div>
						</div>

						<div className='clearRemainderIntervalCont'>
							<span className='enable-severDisNotify-checkbox-ctr justify-content-align-item'>
								<span  className="enable-severDisNotify-label" onClick={()=>{setEnableClearRemainderInterval(!enableClearRemainderInterval)}}>Clear Reminder Interval</span>
								<input className='enable-severDisNotify-checkbox' type="checkbox"  checked={enableClearRemainderInterval} onClick={()=>{setEnableClearRemainderInterval(!enableClearRemainderInterval)}}/>
							</span>
							<div className='enableServerDisNotifyDescription'>If enabled, Alert reminders will be sent at the specified interval below.</div>
							<div className={enableClearRemainderInterval ? "" :'interval-show-cont' }>
								<div className='remainder-interval'>Reminder Interval</div>
								<div className='interval-cont'>
									<div className='every'>Every</div>
									<input className='interval-input' disabled={!enableClearRemainderInterval}></input>
									<div className='minutes'>minutes</div>
								</div>
							</div>
						</div>

						<div className='checkinRemainderIntervalCont'>
							<span className='enable-severDisNotify-checkbox-ctr justify-content-align-item' >
								<span  className="enable-severDisNotify-label" onClick={()=>{setEnableCheckinRemianderInterval(!enableCheckinRemianderInterval)}}>Alert Reminder Interval</span>
								<input className='enable-severDisNotify-checkbox' type="checkbox"  checked={enableCheckinRemianderInterval} onClick={()=>{(setEnableCheckinRemianderInterval(!enableCheckinRemianderInterval))}}/>
							</span>
							<div className='enableServerDisNotifyDescription'>If enabled, Mustering/Checkin reminders will be sent at the specified interval below.</div>
							<div className={enableCheckinRemianderInterval ? "" : 'interval-show-cont'}>
								<div className='remainder-interval'>Reminder Interval</div>
								<div className='interval-cont'>
									<div className='every'>Every</div>
									<input className='interval-input' disabled={!enableCheckinRemianderInterval}></input>
									<div className='minutes'>minutes</div>
								</div>
							</div>
							
						</div>
						
						
					</div> */}
					{/* <div className='escalationColorsCont remainder-interval-cont'>
						<div className='enable-severDisNotify-checkbox-ctr'>
							<div className='escalationTitle'>Escalation Color Levels</div>
							<input className='enable-severDisNotify-checkbox' type="checkbox"  checked={enableEscalation} onClick={()=>{(setEnableEscalation(!enableEscalation))}}/>
						</div>

							{enableEscalation && escalationDetails.map((escalationDetail,index)=>{
								return(<div className='escalationContent'> 
									<div className='escaltionLeftSide'>
										<div className='after'>After</div>
										<input className='interval-input-escalation' value={escalationDetail.escalationInterval} onChange={(event)=>{handleInterval(index,event)}}></input>
										<div className='minutes-escalation'>Minutes</div>
									</div>

									<div className='escaltionRightSide'>
										<div className='useColor'>Use Color</div>
											<label className="color-selector">
												<span className="circle" style={{ background: color.color }} />
												<span>{color.color}</span>
												<input
												type="color"
												value={color.color}
												onChange={(e)=>{handleChange(e)}}
												className="hidden"
												/>
											</label> 
											<div className='iconDiv'>
												<img src={deleteIcon}></img>
											</div>
										<div className='color-picker'>
										<div onClick={()=>{setTriggeredColorPicker(true)}} className="color-selector">
											<span className="circle" style={{ background: escalationDetail.escalationColor }} />
											<span>{escalationDetail.escalationColor}</span>
										</div>
										{triggeredColorPicker ? <CompactPicker onChangeComplete={(e)=>{handleColorsClick(e)}} colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']} className='colors'></CompactPicker> : null}
										</div>
									</div>
								</div>)
							})	
								}
							{enableEscalation && <div>
									<button className='add-level-button justify-content-align-item' onClick={()=>{handleAddLevel()}}>Add Level</button>
									</div>}
					</div> */}

					{validatePermission(userPermissions, "EDIT_ALERT_CONFIGURATION") ? 
							<div className='create-alert-btn-container alert-setup-btn-ctr'>
							<ButtonField label="Save" type="submit" dataTestid="save-alert-configuration-btn" id={"SaveBtn"} onClick={()=>{UpdateAlertSetUpDetails()}}/>
						</div>
						:null}
					 
				
				</div>
				{confirmation ?
				<Confirmation deleteMessage={"Are you sure you want to delete tone "+confirmationDeleteData.name+"?"} successFunc={() => deleteTone()} cancelFunc={() => setConfirmation(false)} />
				: ""}
				{isAudioUploadPopup && 
				<div className='modalAlign'>
					<AudioFileUploadPopUp 
						data={editModalData} 
						onCancel={(e) => {
							fetchAlertSetupDetails()
							setIsAudioUploadPopup(false)
							setEditModalData({})
						}}>
					</AudioFileUploadPopUp>
				</div>}
			</div>
		</React.Fragment>
		
	)
}


export default Configuration
