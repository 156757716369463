import { formatUserName } from '../functions/userNameFormat';

export const makeid = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() *
			charactersLength));
	}
	return result;
}

export const getRoleName = (userListTypes, roleIds) => {
	let roleNameList = []
	if (roleIds.length > 0) {
		roleIds.forEach((id) => {
			userListTypes.forEach((data) => {
				if (data.id === id) {
					roleNameList.push(data.roleName)
				}
			})
		})
		return roleNameList.join(", ")
	}
	return "-"
}

export function cardFilter(item, search) {
	let targetItem;
	if (item.groupName) {
		targetItem = item.groupName
	} else if (item.firstName) {
		targetItem = item.firstName + item.lastName
	} else if (item.item) {
		targetItem = item.item
	} else if (item.roleName) {
		targetItem = item.roleName
	} else if (item.deviceName) {
		targetItem = item.deviceName
	}
	if (search === "") {
		return item
	} else {
		if (targetItem.toLowerCase().includes(search.toLowerCase())) {
			return targetItem;
		} else if (item.firstName || item.lastName) {
			if (formatUserName(item).toLowerCase().includes(search.toLowerCase())) {
				return item;
			}
		}
	}
}

export function downloadImage(blob, filename) {
    const imageUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = filename; // Ensure the filename includes the appropriate extension
    downloadLink.click();
    URL.revokeObjectURL(imageUrl);
}

/**
 * This function is used to move the selected cards from one list to another and update the states accordingly.
 * @param {{selectedData:array, 
 *          setSelectedData:function, 
 *          assignedData:array, 
 *          setAssignedData:function, 
 *          unAssignedData:array, 
 *          setUnAssignedData:function}} params - This object contains the states and setter functions of assigned and unassigned lists and the selected data.
 * @param {array} cardData - This is the array of objects which contains the data inside the cards to be moved.
 * @param {boolean} moveToAssigned - This is a boolean which determines whether the card data should be moved to assigned list or unassigned list.
 */

export const moveCardsData = (
	{ selectedData, setSelectedData, assignedData, setAssignedData, unAssignedData, setUnAssignedData },
	cardData,
	moveToAssigned
) => {
	let tempAssignedValues = assignedData;
	let tempUnAssignedValues = unAssignedData;
	let tempSelectedValues = selectedData;

	cardData.forEach((item) => {
		if (selectedData.includes(item.id)) {
			if (moveToAssigned && tempUnAssignedValues.some(data => data.id === item.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === item.id), 1);
				tempAssignedValues.push(item);
			} else if (!moveToAssigned && tempAssignedValues.some(data => data.id === item.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === item.id), 1);
				tempUnAssignedValues.push(item);
			}
			tempSelectedValues.splice(tempSelectedValues.indexOf(item.id), 1);
		}
	});

	setSelectedData([...tempSelectedValues]);
	setAssignedData([...tempAssignedValues]);
	setUnAssignedData([...tempUnAssignedValues]);
};

/**
 * this is function to update the assigned and unassigned data.
 * @param {array} selectedIds - The array of ids of the assigned data.
 * @param {function} setUnAssignedData - The setter function for the unassigned data state.
 * @param {function} setAssignedData - The setter function for the assigned data state.
 * @param {array} cardData - The array of objects which contains the data of the cards.
 */
export const setCardsData = (selectedIds, setUnAssignedData, setAssignedData, cardData) => {
	let tempAssigenedData = [];
	let tempUnAssignedData = [];
	cardData.map((data) => {
		if (selectedIds.includes(data.id)) {
			tempAssigenedData.push(data)
		} else {
			tempUnAssignedData.push(data)
		}
	})
	setUnAssignedData(tempUnAssignedData)
	setAssignedData(tempAssigenedData)
};

/**
 * This function is used to select/unselect the data on the mobile screen.
 * @param {*} id - The id of the data to be selected/unselected.
 * @param {array} selectedData - The array of already selected data.
 * @param {function} setSelectedData - The setter function to update the selectedData state.
 */
export const selectDataMobile = (id, selectedData, setSelectedData) => {
	let tempSelectedData = selectedData;
	let index = tempSelectedData.indexOf(id)
	if (index > -1) {
		tempSelectedData.splice(index, 1);
	} else {
		tempSelectedData.push(id)
	}
	setSelectedData([...tempSelectedData])
};

/**
 * Selects or deselects all items from the data array based on the checkStatus.
 * If checkStatus is true, all item ids from the data array are added to the selectedData state.
 * If checkStatus is false, the selectedData state is cleared.
 * @param {boolean} checkStatus - Determines whether to select or deselect all items.
 * @param {array} data - The array of objects containing the items to be selected/deselected.
 * @param {function} setSelectedData - The setter function to update the selectedData state.
 */
export const selectAllData = (checkStatus, data, setSelectedData) => {
	const updatedSelection = checkStatus ? data.map((item) => item.id) : [];
	setSelectedData(updatedSelection);
};


/**
 * This function sets the selectedData state to the given id.
 * @param {*} id - The id or array of ids to be selected.
 * @param {function} setSelectedData - The setter function to update the selectedData state.
 */
export const selectData = (id, setSelectedData) => {
	setSelectedData([...id])
};