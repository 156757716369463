import React, { useEffect, useState, useRef } from 'react';
import CloseIcon from '../../../../assets/svg/close-icon.svg';
import DownloadIcon from "../../../../assets/svg/Download-Icon.svg"
import PropTypes from 'prop-types';
import { PDFObject } from 'react-pdfobject';
import "./CategoryFileManager.css";
import { setLoading } from '../../../../redux/action/userAction';
import { useDispatch } from 'react-redux';

function FilePreview(props) {
    const [image,setImage] = useState("");
    const [zoomLevel, setZoomLevel] = useState(100);
    const pdfRef = useRef(null);
    const dispatch = useDispatch();

    const handleZoomIn = () => {
      if (zoomLevel < 200) setZoomLevel(zoomLevel + 10);
    };
  
    useEffect(() => {
        const fetchAndPreviewPdf = async () => {
            try {
                const response = await fetch(props.data.file);
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                dispatch(setLoading(false));
                if (pdfRef.current) {
                    setImage(blobUrl)
                }
            } catch (error) {
                dispatch(setLoading(false));
                console.error('Error fetching or rendering PDF:', error);
            }
        };
        
        if ( props.data.file && props.data.type == "application/pdf" ) {
            fetchAndPreviewPdf();
        } else {
            setImage(props.data.file);
            dispatch(setLoading(false));
        }

    },[]);
  
    const handleZoomOut = () => {
      if (zoomLevel > 50) setZoomLevel(zoomLevel - 10);
    };
    return (
        <div className="modal">
            <div className="modal-content-container file-preview-model">
                <div className="modal-content"  data-testid="file-preview-popup">
                    <div className="modal-title preview-header" data-testid="category-popup-title">
                        <div className='left-element' data-testid="file-title">{props.data.name}</div>
                        <div className='middle-element' style={props.data.type == "application/pdf" ? { display: 'none' } : { display: 'flex' }}>
                        <div className="zoom-controls"
                            style={props.data.type == "application/pdf" ? { display: 'none' } : { display: 'flex' }}>
                            <button title="Zoom Out" data-testid="zoom-out" onClick={handleZoomOut}> - </button>
                            <p title={zoomLevel} data-testid="zoom-level"> {zoomLevel}% </p>
                            <button title="Zoom In" data-testid="zoom-in" onClick={handleZoomIn}> + </button>
                        </div>
                        </div>
                        <div className='right-element'>
                            <a href={image} download="file.png" target="_blank" rel="noreferrer"><img className="download-icon"  style={props.data.type == "application/pdf" ? { display: 'none' } : { display: 'flex' }} data-testid="download-icon" title="Download" src={DownloadIcon} alt=" " /></a>
                            <button data-testid="close-icon" onClick={()=>{props.onCancel()}}><img title="Close" src={CloseIcon} alt=" "/></button>
                        </div>
                    </div>
                   
                    <div className="image-preview">
                        <div style={{
                             transform: `scale(${zoomLevel / 100})`,
                             transformOrigin:  zoomLevel < 100 ? "center" : "top left",
                             width: '100%',
                             height: '95%',
                             transition: 'transform 0.2s ease',
                         }}
                         >
                            {props.data.type == "application/pdf" ? 
                                <PDFObject id='pdf' ref={pdfRef}  url={image} width="100%" data-testid="pdf-preview-component" />
                                : <img src={image} data-testid="image-preview" alt=" " />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
FilePreview.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        file: PropTypes.string,
        name: PropTypes.string
    }),
    onCancel: PropTypes.func
};

export default FilePreview;
