import actiontypes from "../constant/action-types";

const initialState={
    popUp:[],
    groups: [],
    allGroups: [],
    groupInfo: [],
};

export const groupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actiontypes.SET_GROUPS:
            return { ...state, groups: payload };
        case actiontypes.SET_ALL_GROUPS:
            return { ...state, allGroups: payload };
        case actiontypes.SET_GROUP_INFO:
            return { ...state, groupInfo: payload };    
        default:
            return state;
    }
}