import BreadCrumb from "../../../../common/component/BreadCrumb.js";
import BreadCrumbIcon from '../../../../assets/images/breadcrum.png';
import { validatePermission } from "../../../../common/functions/permissionFunctions.js";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import CategoryTable from "./CategoryTable.js";
import "./CategoryFileList.css";
import CategoryFileManager from './CategoryFileManager.js';
import * as API from '../../../../common/api/index.js';
import { setGroupInfo } from '../../../../redux/action/groupAction';
import { setSnackData, setLoading } from '../../../../redux/action/userAction';
import URL from "../../../../common/api/constantURL";
import { setDocCategory } from '../../../../redux/action/docCategoryAction.js';

export const searchFilesWithParents = (data, input = '', dropDown = false) => {
	// Convert searchTerm to lowercase for case-insensitive search
	const term = input.toLowerCase();

	if (term.length <= 0) {
		return data
	}
	const filterDirectories = (directories) => {
		return directories
			.map((directory) => {
				const isCategoryMatch = directory.name.toLowerCase().includes(term);

				const filteredSubDirectories = filterDirectories(directory.categories);
				let filteredFiles = [];
				if (!dropDown) {
					filteredFiles = directory.files.filter((file) =>
						file.name.toLowerCase().includes(term)
					);
				}

				if (isCategoryMatch) {
					return {
						...directory,
						isOpen: true
					};
				}

				if (filteredFiles.length > 0 || filteredSubDirectories.length > 0) {
					return {
						...directory,
						files: filteredFiles,
						categories: filteredSubDirectories,
						isOpen: true
					};
				}
				return null;
			})
			.filter(Boolean);
	};

	const matchingDirectories = dropDown ? filterDirectories(data) : filterDirectories(data.categories);
	if (dropDown) {
		return matchingDirectories;
	} else {
		const matchingFiles = data.uncategorizedFiles.filter((file) =>
			file.name.toLowerCase().includes(term)
		);
		return { uncategorizedFiles: matchingFiles, categories: matchingDirectories }
	}
}; 


export const setToggleState = (categories, comparisonArr) => {
	return categories.map((category) => {
		const matchedItem = comparisonArr.find((item) => item.id === category.id);

		let updatedCategories = [];
		if (category.categories) {
			updatedCategories = setToggleState(category.categories, matchedItem ? matchedItem.categories : []);
		}

		return {
			...category,
			isOpen: matchedItem ? matchedItem.isOpen : false,
			categories: updatedCategories,
		};
	});
}; 

function CategoryFileList() {
    const dispatch = useDispatch();
    const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
    const [showAddFilePopup, setShowAddFilePopup] = useState(false);
    const [isCategory, setIsCategory] = useState(false);
    const categoriesList = useSelector(state => state.docCategoryReducer.categoriesFiles);
	const [data, setData] = useState(categoriesList)
    const userPermissions = useSelector(state => state.userReducer.userPermissions)
    const [search, setSearch] = useState('');
	const [allowReOrder, setAllowReOrder] = useState(false);
	let hasAddDocumentsAndImagesPermission = validatePermission(userPermissions, "ADD_DOCUMENTS_AND_IMAGES");
	let hasEditDocumentsAndImagesPermission = validatePermission(userPermissions, "EDIT_DOCUMENTS_AND_IMAGES");
	const [toggle, setToggle] = useState([]);

	let originalOrder = {
		uncategorizedFiles: [],
		categories: []
	};
	
	useEffect(()=>{
	setToggle([]);
	fetchCategory();
	},[])

	if (allowReOrder) {
		originalOrder.uncategorizedFiles = categoriesList.uncategorizedFiles;
		originalOrder.categories = categoriesList.categories
	}

	useEffect(()=>{
		setData(categoriesList)
	},[categoriesList])

    const allData = searchFilesWithParents(data, search);

    const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/documentsAndImages",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon'/>,type:'img'},
		{link:"/home/application/documentsAndImages",display:"Documents and Images",type:"react"},
	];

	const fetchGroup = async () => {
		let url = URL.groupNamesByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false)); 
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let groupList = response.result.result;
				dispatch(setGroupInfo(groupList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData));
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData));
		}
	}

  const fetchCategory = async () => {
    let url = URL.categories + "?facilityId=" + localStorage.getItem("facilityId");
    if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
      url = URL.categories
    }
    dispatch(setLoading(true))
    let response = await API.getAPI(url);
    fetchGroup();
    dispatch(setLoading(false));
    if(response.fetchStatus === "success"){
      if(response.result.status === "success"){
        let categoryList = response.result.result;
		categoryList.categories = setToggleState(categoryList.categories, toggle);
        dispatch(setDocCategory(categoryList));
      }else{
        let snackData = {
          showSnack:true,
          snackMessage:response.result.message,
          snackVariant:"error"
        }
        dispatch(setSnackData(snackData));
      }
    }else if(response.fetchStatus === "failure"){
      let snackData = {
        showSnack:true,
        snackMessage:response.result.message,
        snackVariant:"error"
      }
      dispatch(setSnackData(snackData));
    }
  }

	const saveDisplayOrder = async () => {
		const updatedDisplayOrder = getUpdatedOrder(originalOrder, categoriesList);
		dispatch(setLoading(true));
		let response = null;
		if (updatedDisplayOrder) {
			response = await API.putAPI(URL.reorder, updatedDisplayOrder);
			dispatch(setLoading(false));
			handleAPIResponse(response, setAllowReOrder);
			fetchCategory();
		}
	}
	
	function getUpdatedOrder(original, updated) {
		const changes = [];

		// Compare uncategorizedFiles
		if (original?.uncategorizedFiles && updated?.uncategorizedFiles) {
		  const originalUncategorizedMap = new Map(
			original.uncategorizedFiles.map((file) => [file.id, file])
		  );
	  
		  updated.uncategorizedFiles.forEach((updatedFile, index) => {
			const newDisplayOrder = updated.uncategorizedFiles.length - index;
			const originalFile = originalUncategorizedMap.get(updatedFile.id);
			if (
			  originalFile &&
			  originalFile.displayOrder !== newDisplayOrder
			) {
			  changes.push({
				id: updatedFile.id,
				parentId: updatedFile.parentId,
				displayOrder: newDisplayOrder,
			  });
			}
		  });
		}
	  
		// Compare categories
		const processCategories = (originalCategories, updatedCategories) => {
		  const originalMap = new Map(
			originalCategories.map((cat) => [cat.id, cat])
		  );
	  
		  updatedCategories.forEach((updatedCategory, index) => {
			const newDisplayOrder = updatedCategories.length - index;
			const originalCategory = originalMap.get(updatedCategory.id);
			
			// Check if the displayOrder has changed
			if (originalCategory && originalCategory.displayOrder !== newDisplayOrder) {
			  changes.push({
				id: updatedCategory.id,
				parentId: updatedCategory.parentId,
				displayOrder: newDisplayOrder,
			  });
			}
	  
			// Process subcategories recursively
			if (updatedCategory.categories?.length > 0) {
			  const originalSubcategories = originalCategory?.categories || [];
			  processCategories(originalSubcategories, updatedCategory.categories);
			}
	  
			// Process files in the category
			if (updatedCategory.files?.length > 0) {
			  const originalFilesMap = new Map((originalCategory?.files || []).map((file) => [file.id, file]));
			  updatedCategory.files.forEach((updatedFile, fileIndex) => {
				const newFileDisplayOrder = updatedCategory.files.length - fileIndex;
				const originalFile = originalFilesMap.get(updatedFile.id);
	  
				if (originalFile && originalFile.displayOrder !== newFileDisplayOrder) {
				  changes.push({
					id: updatedFile.id,
					parentId: updatedFile.parentId,
					displayOrder: newFileDisplayOrder,
				  });
				}
			  });
			}
		  });
		};
	  
		if (original?.categories && updated?.categories) {
		  processCategories(original.categories, updated.categories);
		}
	  
		return changes;
	  }
	   
	const handleAPIResponse = (response, setState) => {
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				const snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success",
				};
				setState(false)
				dispatch(setSnackData(snackData));
			} else if (response.result.status === "failure") {
				const snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error",
				};
				dispatch(setLoading(false));
				dispatch(setSnackData(snackData));
			}
		} else {
			const snackData = {
				showSnack: true,
				snackMessage: "Server down. Unable to process the request.",
				snackVariant: "error",
			};
			dispatch(setLoading(false));
			dispatch(setSnackData(snackData));
		}
	};

	const docCategoryPopUpHandler = (isFile = false) => {
		setToggle(categoriesList.categories);
		if (!isFile) {
			setShowAddCategoryPopup(true); 
			setIsCategory(true)
		} else {
			setShowAddFilePopup(true)
			setIsCategory(false)
		}
	}

	useEffect(() => {
		allowReOrder && setToggle(categoriesList.categories);
	}, [allowReOrder]);

	return (
		<div className='mustering-location-container' data-testid="events-location-container" id="wrapper">
	        <BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
            <div className='list-page-title-div'>
				<div className='list-page-title' data-testid="category-title">Documents and Images</div>
			</div>
				<div className='button_parent_div button-and-pagination-wrapper'>
					<div style={{
						pointerEvents: allowReOrder ? "none" : "auto",
						opacity: allowReOrder ? 0.4 : 1, 
					}} className="buttons-wrapper" data-testid="buttons-wrapper">
						{hasAddDocumentsAndImagesPermission && 
						<>
						<button className='common-button' data-testid="add-category-btn" id="add-event" onClick={()=>docCategoryPopUpHandler(false)}>Add Category</button>
						<button className='common-button' data-testid="add-files-btn" id="add-event" onClick={()=>docCategoryPopUpHandler(true)}>Add New File</button>
						</>
						}
						<div className="search mustering-location-search-filter documents-search">
							<input type="text" className="usersSearch" placeholder="Search" data-testid="event_location_search" id="event-location-search" 
							value={search} onChange={(e) => setSearch(e.target.value)}
							></input>
							<div style={{ display: "flex" }} className="reclear">
								<div data-testid="search-reset-btn" id="search-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
							</div>
						</div>
					</div>
						{(hasAddDocumentsAndImagesPermission || hasEditDocumentsAndImagesPermission) && (categoriesList?.uncategorizedFiles?.length > 0 || categoriesList?.categories?.length > 0) && <div  className="reorder-btn">
						{allowReOrder && <button className="cancel-button" data-testid="cancel-reorder-btn" onClick={()=>{setAllowReOrder(false); fetchCategory()}}> Cancel </button>}
						<button className='common-button' data-testid="add-category-btn" id="add-event" onClick={() => {setAllowReOrder(true); {allowReOrder && saveDisplayOrder()}}}>{allowReOrder ? "Save" : "Reorder"}</button>
						</div>}
				</div>
			{
				(showAddCategoryPopup || showAddFilePopup) && <CategoryFileManager
				mode={showAddCategoryPopup ? "CATEGORY" : "FILE"}
				data={{}}
				isCategory={isCategory} 
				onCancel={(e) => {
					setShowAddCategoryPopup(false);
					setShowAddFilePopup(false);
					setIsCategory(false);
					fetchCategory()
			}}
				/>
			}
            <CategoryTable currentItems={allData} draggable={allowReOrder}/>

		</div>
	)
}

export default CategoryFileList
