import { useState } from "react";
import './NoCurrentEvent.css'
import musteringIcon from '../../../assets/svg/mustering-icon.svg';
import { useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import Mustering from './Mustering';
function NoCurrentEvent() {
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const navigate = useNavigate()
	const history = false;
	return (<>
		{!history?
		<div className='no-current-event-container' data-testid="no-current-event-container">
			<div className="list-page-title-div">
				<div className="list-page-title" data-testid="mustering-head">
					Mustering
				</div>
			</div>
            <div className="row no-current-event-row">
					<div className="no-current-event-card">
					<div className="no-current-event_title" data-testid="mustering-title">No Current Events</div>
						<div className="no-current-event-img">
                    		<img src={musteringIcon} alt=" " className='user_icon' title="add-user-icon" data-testid="user_icon"/>
						</div>
					<div className="no-current-event-content" data-testid="no-current-event-content">There are currently no ongoing events.</div>
					{validatePermission(userPermissions, "VIEW_MUSTERING_HISTORY") &&<>
						<div className="no-current-event-content" data-testid="navigate-content">Click to see a list of previous events and download reporting details.</div>
						{/* <button className='no-current-event-btn'  onClick={() => navigate("/home/reports/eventHistory")}>View History</button> */}
						<button className='no-event-button' data-testid="navigate-btn" onClick={() => navigate("/home/reports/event-history")}>View History</button>
					</>}
					
				</div>
			</div>
		</div>:
		<Mustering />}
		</>
	);
}

export default NoCurrentEvent;
